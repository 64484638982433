import * as React from "react";
import SearchSuggestionBox from "../../search-suggestion-box";
import { Icon } from "@blueprintjs/core";
import GenomeTile from "../../genome-tile";
import { performSearch } from "../../../utils/search";

class ATCCHomePage extends React.PureComponent {
  renderGenomeTiles = () => {
    const genomes = [
      {
        name: "Rabies virus (ATCC® VR-959™)",
        metadataText: "Added 4/29/2024",
        uuid: "e0ccaa6772e24002",
        iconName: "covid-19",
      },
      {
        name: "Starmerella bombicola (ATCC® 22214™)",
        metadataText: "Added 4/29/2024",
        uuid: "a200c8a6d45643c2",
        iconName: "mycology-icon",
      },
      {
        name: "Pseudomonas olympii (ATCC® TSD-406™)",
        metadataText: "Added 3/5/2024",
        uuid: "2f6b44b212e24b3c",
        iconName: "jerry",
      },
    ];

    return genomes.map((g, idx) => {
      return (
        <GenomeTile
          name={g.name}
          metadataText={g.metadataText}
          idx={idx}
          key={g.uuid}
          uuid={g.uuid}
          iconName={g.iconName}
        />
      );
    });
  };

  render() {
    return (
      <div className="atcc-homepage">
        <div className="welcome">
          <h1>Welcome to the ATCC Genome Portal</h1>
          <p>The only authenticated reference genome database for ATCC microbes</p>
          <a className="view-genomes" href="/genomes">
            {"VIEW ALL GENOMES  "}
            <Icon icon="chevron-right" />
          </a>

          <h2>Search for a genome</h2>
          <SearchSuggestionBox performSearch={performSearch} />

          <div className="recently-updated">
            <h2>Recently published</h2>
            <div className="genome-tile-container">{this.renderGenomeTiles()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default ATCCHomePage;
